ko.bindingHandlers.href = {
    update: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        var value = ko.unwrap(valueAccessor());
        $(element).attr('href', value || '');
    }
};

ko.bindingHandlers.src = {
    update: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        var value = ko.unwrap(valueAccessor());
        $(element).attr('src', value || '');
    }
};

// It's required binding - valueUpdate: 'afterkeydown'
ko.bindingHandlers.enterkey = {
    init: function(element, valueAccessor, allBindings, viewModel) {
        var callback = valueAccessor();
        $(element).keydown(function(event) {
            var keyCode = (event.which ? event.which : event.keyCode);
            if (keyCode === 13) {
                callback.call(viewModel, event);
                return false;
            }
            return true;
        });
    }
};

ko.bindingHandlers.modal = {
    init: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        var observable = valueAccessor();
        $(element).on('shown.bs.modal', function() {
            if (ko.isWritableObservable(observable))
                observable(true);
        }).on('hidden.bs.modal', function() {
            if (ko.isWritableObservable(observable))
                observable(false);
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
                $(element).modal("destroy");
        });
    },
    update: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        var value = ko.unwrap(valueAccessor());
        if (value)
            $(element).modal({});
        else
            $(element).modal('hide');
    }
};

ko.bindingHandlers.slideDown = {
    update: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        var value = ko.unwrap(valueAccessor());
        if (value)
            $(element).slideDown();
        else
            $(element).slideUp();
    }
};

ko.bindingHandlers.visibleSlideLeft = {
    init: function(element, valueAccessor) {
        var value = ko.utils.unwrapObservable(valueAccessor());
        $(element).toggle(value);
    },
    update: function(element, valueAccessor) {
        var value = ko.utils.unwrapObservable(valueAccessor());
        value ?
            $(element).show("slide", { direction: "right" }, 300) : //show
            $(element).hide("slide", { direction: "left" }, 0); // hide
    }
};

// check https://github.com/hakimel/Ladda for references
ko.bindingHandlers.spinBtn = {
    init: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        $(element).addClass("ladda-button");
        var dataStyleAttr = $(element).attr("data-style");
        if (dataStyleAttr == undefined)
            $(element).attr("data-style", "expand-left");
        var progress = Ladda.create(element);
        $(element).data("spinBtn", progress);
    },
    update: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        var value = ko.unwrap(valueAccessor());
        var progress = $(element).data("spinBtn");
        if (value) {
            progress.start();
        }
        else {
            progress.stop();
        }
    }
};

ko.bindingHandlers.truncate = {
    update: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        var value = ko.unwrap(valueAccessor()) || '';
        var maxLength = allBindings.get('length');
        if (value.length > maxLength)
            value = value.substring(0, maxLength - 3) + '...';

        $(element).text(value);
    }
};

ko.bindingHandlers.gmailAccounts = {
    init: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        // Remove all existing <option>s.
        while (element.length > 0) {
            element.remove(0);
        }

        // Ensures that the binding processor doesn't try to bind the options
        return { 'controlsDescendantBindings': true };
    },
    update: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        var jqueryElement = $(element);
        var gmailAccounts = ko.unwrap(valueAccessor());

        if (gmailAccounts.length === 0)
            return;

        _.each(gmailAccounts,
            function(gmailAccount) {
                jqueryElement.append($("<option></option>")
                    .attr("data-content",
                        "<span><i class='fa fa-envelope-o' aria-hidden='true'></i> " +
                        gmailAccount.FromFormatted +
                        "</span>")
                    .data(gmailAccount)
                    .text(gmailAccount.FromFormatted));

                _.each(gmailAccount.SendAsAliases,
                    function(sendAsAlias) {
                        jqueryElement.append($("<option></option>")
                            .attr("class", "left25")
                            .data(sendAsAlias)
                            .text(sendAsAlias.FromFormatted));
                    });
            });
        jqueryElement.selectpicker("refresh");
        jqueryElement.selectpicker("val", gmailAccounts[0].FromFormatted);
    }
};

ko.bindingHandlers.selectPicker = {
    after: ["options"],   /* KO 3.0 feature to ensure binding execution order */
    init: function(element, valueAccessor, allBindingsAccessor) {
        var $element = $(element);
        var value = ko.unwrap(valueAccessor());
        $element.addClass("selectpicker").selectpicker(value);

        var hideTooltip = value["hideTooltip"];

        // we should apply style to whole controle instead button only (in the 'style' property)
        // $element.parent().addClass(value["controlStyle"]);

        var doRefresh = function() {
            $element.selectpicker("refresh");

            //if (hideTooltip) {
            //    $element.removeAttr("data-title");
            //}
        }, subscriptions = [];

        // KO 3 requires subscriptions instead of relying on this binding's update
        // function firing when any other binding on the element is updated.

        // Add them to a subscription array so we can remove them when KO
        // tears down the element.  Otherwise you will have a resource leak.
        var addSubscription = function(bindingKey) {
            var targetObs = allBindingsAccessor.get(bindingKey);

            if (targetObs && ko.isObservable(targetObs)) {
                subscriptions.push(targetObs.subscribe(doRefresh));
            }
        };

        //handle the field changing for gmailAccounts binding
        ko.utils.registerEventHandler(element, "changed.bs.select", function(event, clickedIndex, newValue, oldValue) {
            var allBindings = allBindingsAccessor();
            if (allBindings["gmailAccounts"]) {
                allBindings["selectedGmailAccount"]($(event.currentTarget.selectedOptions[0]).data());
            }
        });

        addSubscription("options");
        addSubscription("value");           // Single
        addSubscription("selectedOptions"); // Multiple

        ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
            while (subscriptions.length) {
                subscriptions.pop().dispose();
            }
        });

        $(element).on("rendered.bs.select refreshed.bs.select changed.bs.select loaded.bs.select", function(e) {
            if ($(e.target).hasClass("no-title")) {
                //$(e.target).next().children().removeAttr("title");
                //$(e.target).next().children().removeAttr("data-original-title");

                $(e.target).parent().children().removeAttr("title");
                $(e.target).parent().children().removeAttr("data-original-title");
            }
        });
    },
    update: function(element, valueAccessor, allBindingsAccessor) {
    }
};

ko.bindingHandlers.tags = {
    init: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        ko.bindingHandlers.options.init(element, valueAccessor, allBindings, viewModel, bindingContext);
        var value = ko.unwrap(valueAccessor());
        $(element).prop('multiple', true)
            .css('width', '100%')
            .find('option')
            .attr('selected', 'selected');
    },
    update: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        ko.bindingHandlers.options.update(element, valueAccessor, allBindings, viewModel, bindingContext);
        var value = ko.unwrap(valueAccessor());
        $(element).select2('destroy').select2({ tags: true }).find('option')
            .attr('selected', 'selected')
            .trigger("change");
        //$(element).data('select2').$dropdown.addClass('hide');
    }
};

ko.bindingHandlers.select2 = {
    init: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        var values = ko.unwrap(valueAccessor());
        var options = allBindings()["select2options"];

        $(element).off("select2:select");

        $(element)
            .find("option")
            .remove();

        var selectAllOption = allBindings()["select2selectAll"];

        _.each(values,
            function(value) {
                var $option = selectAllOption ? $("<option selected></option>") : $("<option></option>");
                $option.val(value);
                $option.text(value);
                $(element).append($option);
            });

        $(element).css("width", "100%").select2(options);

        if (allBindings()["value"]) {
            allBindings()["value"].subscribe(function(newValue) {
                $(element).val(newValue).trigger("change");
            });
        }

        $(element).on("select2:select", function(event) {
            if (allBindings()["select2onChange"]) {
                allBindings()["select2onChange"](event);
            }
        });

        $(element).on("select2:unselect", function(event) {
            if (allBindings()["select2onChange"]) {
                allBindings()["select2onChange"](event);
            }
        });
    },
    update: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        var values = ko.unwrap(valueAccessor());
        var options = allBindings()["select2options"];
        var previousValue = $(element).val();

        $(element).off("select2:select");

        $(element)
            .find("option")
            .remove();

        var selectAllOption = allBindings()["select2selectAll"];

        _.each(values,
            function(value) {
                var $option = selectAllOption ? $("<option selected></option>") : $("<option></option>");
                $option.val(value);
                $option.text(value);
                $(element).append($option);
            });

        $(element).css("width", "100%").select2(options);
        $(element).val(previousValue).trigger("change");

        $(element).on("select2:select", function(event) {
            if (allBindings()["select2onChange"]) {
                allBindings()["select2onChange"](event);
            }
        });

        $(element).on("select2:unselect", function(event) {
            if (allBindings()["select2onChange"]) {
                allBindings()["select2onChange"](event);
            }
        });
    }
};

ko.bindingHandlers.select2Simple = {
    init: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        var select2Options = valueAccessor() || {};
        $(element).select2(select2Options);
    },
    update: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        var select2Options = valueAccessor() || {};

        if (!$(element).hasClass("select2-hidden-accessible")) {
            // if Select2 has not been initialized
            $(element).select2(select2Options);
        }
    }
};


ko.bindingHandlers.select2Tags = {
    init: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        var select2Options = valueAccessor() || {};

        select2Options.tags = true;
        select2Options.multiple = true;
        select2Options.allowClear = true;
        select2Options.width = "100%";
        select2Options.tokenSeparators = [",", "\t", " "];

        $(element).select2(select2Options);

        //$(element).on("select2:open", function(e) { console.log("select2:open", e); });
        //$(element).on("select2:close", function(e) { console.log("select2:close", e); });
        $(element).on("select2:select", function(e) {
            //console.log("select2:select", e);
            //valueBinding($(element).val());
        });
        $(element).on("select2:unselect", function(e) {
            //console.log("select2:unselect", e);
            //valueBinding($(element).val());
        });
        $(element).on("change", function(e) {
            // console.log("change");
        });
    },
    update: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        var select2Options = valueAccessor() || {};

        select2Options.tags = true;
        select2Options.multiple = true;
        select2Options.allowClear = true;
        select2Options.width = "100%";
        select2Options.tokenSeparators = [",", "\t", " "];

        $(element).select2(select2Options);
        //$(element).select2(select2Options);
    }
};


ko.bindingHandlers.stopBinding = {
    init: function() {
        return { controlsDescendantBindings: true };
    }
};

function getBindingValue(valueAccessor, propertyName) {
    var value = ko.isObservable(ko.utils.unwrapObservable(valueAccessor)()[propertyName]) || typeof ko.utils.unwrapObservable(valueAccessor)()[propertyName] === "function" ?
        ko.utils.unwrapObservable(valueAccessor)()[propertyName]() :
        ko.utils.unwrapObservable(valueAccessor)()[propertyName];

    return value;
}

ko.bindingHandlers.slider = {
    init: function(element, valueAccessor, allBindingsAccessor) {
        var minValue = ko.utils.unwrapObservable(valueAccessor)().minValue();

        var maxValue = ko.isObservable(ko.utils.unwrapObservable(valueAccessor)().maxValue) ?
            ko.utils.unwrapObservable(valueAccessor)().maxValue() :
            ko.utils.unwrapObservable(valueAccessor)().maxValue;

        var isActive = ko.isObservable(ko.utils.unwrapObservable(valueAccessor)().isActive) || typeof ko.utils.unwrapObservable(valueAccessor)().isActive === "function" ?
            ko.utils.unwrapObservable(valueAccessor)().isActive() :
            ko.utils.unwrapObservable(valueAccessor)().isActive;

        var enabled = ko.isObservable(ko.utils.unwrapObservable(valueAccessor)().enabled) || typeof ko.utils.unwrapObservable(valueAccessor)().enabled === "function" ?
            ko.utils.unwrapObservable(valueAccessor)().enabled() :
            ko.utils.unwrapObservable(valueAccessor)().enabled;

        var max = getBindingValue(valueAccessor, "max");

        var maxInfinity = getBindingValue(valueAccessor, "maxInfinity");
        if (!maxInfinity)
            maxInfinity = false;

        if (maxValue === null || maxValue !== undefined) {
            if (maxValue == null || maxValue === undefined)
                maxValue = 0;
            if (minValue == null || minValue === undefined)
                minValue = 0;
            $(element).slider({
                value: [(!isActive ? 0 : minValue),
                (!isActive ? 0 : maxValue)],
                selection: ko.utils.unwrapObservable(valueAccessor)().selection,
                enabled: enabled,
                max: max,
                maxInfinity: maxInfinity
            });
        }
        else
            $(element).slider({
                value: minValue,
                selection: ko.utils.unwrapObservable(valueAccessor)().selection,
                enabled: enabled,
                max: max,
                maxInfinity: maxInfinity
            });

        $(element).change(function(e) {
            var minValue = ko.utils.unwrapObservable(valueAccessor)().minValue;
            var maxValue = ko.utils.unwrapObservable(valueAccessor)().maxValue;
            if (e.value.newValue[1]) {
                minValue(e.value.newValue[0]);
                maxValue(e.value.newValue[1]);
            }
            else {
                minValue(e.value.newValue);
            }
        });
    },
    update: function(element, valueAccessor) {

        var isActive = ko.isObservable(ko.utils.unwrapObservable(valueAccessor)().isActive) || typeof ko.utils.unwrapObservable(valueAccessor)().isActive === "function" ?
            ko.utils.unwrapObservable(valueAccessor)().isActive() :
            ko.utils.unwrapObservable(valueAccessor)().isActive;

        var enabled = ko.isObservable(ko.utils.unwrapObservable(valueAccessor)().enabled) || typeof ko.utils.unwrapObservable(valueAccessor)().enabled === "function" ?
            ko.utils.unwrapObservable(valueAccessor)().enabled() :
            ko.utils.unwrapObservable(valueAccessor)().enabled;

        var minValue = ko.utils.unwrapObservable(valueAccessor)().minValue();
        var value = $(element).slider('getValue');

        var max = getBindingValue(valueAccessor, "max");
        var minValue = getBindingValue(valueAccessor, "minValue");
        var maxValue = getBindingValue(valueAccessor, "maxValue");

        if (!isActive)
            $(element).slider('deactivate');

        if (enabled == true)
            $(element).slider('enable');
        if (enabled == false)
            $(element).slider('disable');


        if (max && $(element).slider('getAttribute', 'max') !== max) {
            $(element).slider('setAttribute', 'max', max);
            $(element).slider('setValue', value);
        }


        if (Array.isArray(value)) {
        }
        else {
            if (value && (minValue || minValue == 0) && value !== minValue) {
                $(element).slider('setValue', minValue);
            }
        }

        //if (minValue !== $(element).slider('getValue')) {
        //    var test = 2222;
        //}


        //if ($(element).slider('getValue') !== ko.utils.unwrapObservable(valueAccessor)().value() && ko.utils.unwrapObservable(valueAccessor)().isActive())
        //    $(element).slider('setValue', ko.utils.unwrapObservable(valueAccessor)().value(), true);

    }
};

ko.bindingHandlers.toggle = {
    init: function(element, valueAccessor, allBindingsAccessor) {

        var checked = ko.isObservable(ko.utils.unwrapObservable(valueAccessor)().checked) || typeof ko.utils.unwrapObservable(valueAccessor)().checked === "function" ?
            ko.utils.unwrapObservable(valueAccessor)().checked() :
            ko.utils.unwrapObservable(valueAccessor)().checked;

        var unchecked = ko.isObservable(ko.utils.unwrapObservable(valueAccessor)().unchecked) || typeof ko.utils.unwrapObservable(valueAccessor)().unchecked === "function" ?
            ko.utils.unwrapObservable(valueAccessor)().unchecked() :
            ko.utils.unwrapObservable(valueAccessor)().unchecked;

        var cssList = ko.isObservable(ko.utils.unwrapObservable(valueAccessor)().css) || typeof ko.utils.unwrapObservable(valueAccessor)().css === "function" ?
            ko.utils.unwrapObservable(valueAccessor)().css() :
            ko.utils.unwrapObservable(valueAccessor)().css;
        $(element).attr("data-toggle", "toggle");

        if (checked != undefined) {
            $(element).prop('checked', checked ? "checked" : "");
            $(element).bootstrapToggle();
            $(element).updateCss(cssList);

            $(element).change(function(e) {
                ko.utils.unwrapObservable(valueAccessor)().checked($(e.target).is(":checked"));
            });
        }
        if (checked == undefined && unchecked != undefined) {
            $(element).prop('checked', !unchecked ? "checked" : "");
            $(element).bootstrapToggle();
            $(element).updateCss(cssList);

            $(element).change(function(e) {
                ko.utils.unwrapObservable(valueAccessor)().unchecked(!$(e.target).is(":checked"));
            });
        }
    },
    update: function(element, valueAccessor) {

        var checked = ko.isObservable(ko.utils.unwrapObservable(valueAccessor)().checked) || typeof ko.utils.unwrapObservable(valueAccessor)().checked === "function" ?
            ko.utils.unwrapObservable(valueAccessor)().checked() :
            ko.utils.unwrapObservable(valueAccessor)().checked;

        var unchecked = ko.isObservable(ko.utils.unwrapObservable(valueAccessor)().unchecked) || typeof ko.utils.unwrapObservable(valueAccessor)().unchecked === "function" ?
            ko.utils.unwrapObservable(valueAccessor)().unchecked() :
            ko.utils.unwrapObservable(valueAccessor)().unchecked;

        var enabled = ko.isObservable(ko.utils.unwrapObservable(valueAccessor)().enabled) || typeof ko.utils.unwrapObservable(valueAccessor)().enabled === "function" ?
            ko.utils.unwrapObservable(valueAccessor)().enabled() :
            ko.utils.unwrapObservable(valueAccessor)().enabled;

        if (checked != undefined) {
            if (checked !== $(element).is(":checked")) {
                $(element).prop('checked', checked ? "checked" : "");
                $(element).bootstrapToggle("update");
            }
        }
        if (checked == undefined && unchecked != undefined) {
            if (unchecked !== !$(element).is(":checked")) {
                $(element).prop('checked', !unchecked ? "checked" : "");
                $(element).bootstrapToggle("update");
            }
        }
        if (enabled || enabled == undefined) {
            $($(element).parent()).removeClass("disabled");
            $(element).attr('disabled', false);
        }
        else {
            $($(element).parent()).addClass("disabled");
            $(element).attr('disabled', true);
        }

        var cssList = ko.isObservable(ko.utils.unwrapObservable(valueAccessor)().css) || typeof ko.utils.unwrapObservable(valueAccessor)().css === "function" ?
            ko.utils.unwrapObservable(valueAccessor)().css() :
            ko.utils.unwrapObservable(valueAccessor)().css;

        $(element).updateCss(cssList);
    }
};

ko.bindingHandlers.checkbox = {
    init: function(element, valueAccessor, allBindingsAccessor) {

        var checked = ko.isObservable(ko.utils.unwrapObservable(valueAccessor)().checked) || typeof ko.utils.unwrapObservable(valueAccessor)().checked === "function" ?
            ko.utils.unwrapObservable(valueAccessor)().checked() :
            ko.utils.unwrapObservable(valueAccessor)().checked;

        var unchecked = ko.isObservable(ko.utils.unwrapObservable(valueAccessor)().unchecked) || typeof ko.utils.unwrapObservable(valueAccessor)().unchecked === "function" ?
            ko.utils.unwrapObservable(valueAccessor)().unchecked() :
            ko.utils.unwrapObservable(valueAccessor)().unchecked;

        var enabled = ko.isObservable(ko.utils.unwrapObservable(valueAccessor)().enabled) || typeof ko.utils.unwrapObservable(valueAccessor)().enabled === "function" ?
            ko.utils.unwrapObservable(valueAccessor)().enabled() :
            ko.utils.unwrapObservable(valueAccessor)().enabled;

        var checkbox = $($(element).parent().find("input[type=checkbox]"));

        if (!valueAccessor().hasOwnProperty("checked"))
            checked = !unchecked;
        checkbox.prop("checked", checked);

        $(element).click(function(e) {
            var checkbox = $($(element).parent().find("input[type=checkbox]"));

            if (checkbox.is(":disabled"))
                return;

            var setValue = !checkbox.is(":checked");
            checkbox.prop("checked", setValue);
            checkbox.trigger("change");

            if (valueAccessor().hasOwnProperty("checked"))
                ko.utils.unwrapObservable(valueAccessor)().checked(setValue);
            else
                ko.utils.unwrapObservable(valueAccessor)().unchecked(setValue);
        });
    },
    update: function(element, valueAccessor) {
        var checked = ko.isObservable(ko.utils.unwrapObservable(valueAccessor)().checked) || typeof ko.utils.unwrapObservable(valueAccessor)().checked === "function" ?
            ko.utils.unwrapObservable(valueAccessor)().checked() :
            ko.utils.unwrapObservable(valueAccessor)().checked;

        var unchecked = ko.isObservable(ko.utils.unwrapObservable(valueAccessor)().unchecked) || typeof ko.utils.unwrapObservable(valueAccessor)().unchecked === "function" ?
            ko.utils.unwrapObservable(valueAccessor)().unchecked() :
            ko.utils.unwrapObservable(valueAccessor)().unchecked;

        var enabled = ko.isObservable(ko.utils.unwrapObservable(valueAccessor)().enabled) || typeof ko.utils.unwrapObservable(valueAccessor)().enabled === "function" ?
            ko.utils.unwrapObservable(valueAccessor)().enabled() :
            ko.utils.unwrapObservable(valueAccessor)().enabled;

        if (!valueAccessor().hasOwnProperty("checked"))
            checked = !unchecked;

        var checkbox = $($(element).parent().find("input[type=checkbox]"));
        var checkboxValue = checkbox.is(":checked");

        if (checkboxValue != checked) {
            checkbox.prop("checked", checked);
            checkbox.trigger("change");
        }

        if (valueAccessor().hasOwnProperty("enabled")) {
            if (!enabled) {
                checkbox.attr("disabled", "disabled");
            } else {
                checkbox.removeAttr("disabled");
            }
        }
    }
};

ko.bindingHandlers.checkboxNew = {
    init: function(element, valueAccessor, allBindingsAccessor) {
        $(element).click(function(e) {
            var checkbox = $($(element).parent().find("input[type=checkbox]"));

            if (checkbox.is(":disabled"))
                return;

            var setValue = !checkbox.is(":checked");
            checkbox.prop("checked", setValue);
            checkbox.trigger("change");
        });
    },
    update: function(element, valueAccessor) {

    }
};

ko.bindingHandlers.inverseChecked = {
    init: function(element, valueAccessor, allBindingsAccessor) {
        var value = valueAccessor();
        var interceptor = ko.computed({
            read: function() {
                return !value();
            },
            write: function(newValue) {
                value(!newValue);
            },
            disposeWhenNodeIsRemoved: element
        });

        var newValueAccessor = function() { return interceptor; };


        //keep a reference, so we can use in update function
        ko.utils.domData.set(element, "newValueAccessor", newValueAccessor);
        //call the real checked binding's init with the interceptor instead of our real observable
        ko.bindingHandlers.checked.init(element, newValueAccessor, allBindingsAccessor);
    },
    update: function(element, valueAccessor) {
        if (ko.bindingHandlers.checked.update == null)
            return;
        //call the real checked binding's update with our interceptor instead of our real observable
        ko.bindingHandlers.checked.update(element, ko.utils.domData.get(element, "newValueAccessor"));
    }
};

ko.bindingHandlers.listmanager = {
    init: function(element, valueAccessor, allBindingsAccessor) {

        ListManagerModel({
            element: element,
            displayText: getBindingValue(valueAccessor, "displayText"),
            placeHolder: getBindingValue(valueAccessor, "placeHolder"),
            autoSelectFirst: getBindingValue(valueAccessor, "autoSelectFirst"),
            deleteEnabled: getBindingValue(valueAccessor, "deleteEnabled"),
            displayAll: getBindingValue(valueAccessor, "displayAll"),
            dropDownSelectText: getBindingValue(valueAccessor, "dropDownSelectText"),
            disableSelect: valueAccessor().disableSelect,
            onItemAdded: valueAccessor().onItemAdded,
            onBeforeItemAdded: valueAccessor().onBeforeItemAdded,
            onItemDeleted: valueAccessor().onItemDeleted,
            hideDelete: valueAccessor().hideDelete,
            items: valueAccessor().items,
            value: valueAccessor().value,
            multiSelect: valueAccessor().multiSelect,
            canAdd: valueAccessor().canAdd,
            multiDisplay: valueAccessor().multiDisplay,
            tags: valueAccessor().tags,
            selectedItemsFormatter: valueAccessor().selectedItemsFormatter,
            listItemFormatter: valueAccessor().listItemFormatter,
            onListItemClick: valueAccessor().onListItemClick
        });
    },
    update: function(element, valueAccessor) {

    }
};

ko.bindingHandlers.relationshipsmanager = {
    init: function(element, valueAccessor, allBindingsAccessor) {

        RelationshipControlModel({
            element: element,
            prospectUserId: valueAccessor().prospectUserId,
            loadImmediately: getBindingValue(valueAccessor, "loadImmediately")
        });

    },
    update: function(element, valueAccessor) {

    }
};

ko.bindingHandlers.perfectScrollbar = {
    init: function(element, valueAccessor, allBindingsAccessor) {

        var options = valueAccessor() || {};
        $(element).perfectScrollbar(options);

        ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
            $(element).perfectScrollbar("destroy");
        });

        //ko.utils.registerEventHandler(element, "mouseenter", function() {
        //    $(element).perfectScrollbar("update");
        //});

        $(element).scroll(function() {
            var scrollSettings = {
                scrollTop: $(element).scrollTop(),
                outerHeight: $(element).outerHeight(),
                scrollHeight: element.scrollHeight
            };

            if (options.scrollTop)
                options.scrollTop($(element).scrollTop());
            if (options.scrollOnChange)
                options.scrollOnChange(scrollSettings);
        });
        if(options.reachEndY != null) {
            $(element)[0].addEventListener("ps-y-reach-end", options.reachEndY);
        }
    },
    update: function(element, valueAccessor, allBindingsAccessor) {
        if (valueAccessor().scrollTop && valueAccessor().scrollTop() != $(element).scrollTop()) {
            $(element).scrollTop(valueAccessor().scrollTop());
        }
        $(element).perfectScrollbar("update");
    }
};

// For generating unique id in the templates https://stackoverflow.com/a/9233786/596688
ko.bindingHandlers.uniqueId = {
    init: function(element) {
        element.id = ko.bindingHandlers.uniqueId.prefix + (++ko.bindingHandlers.uniqueId.counter);
    },
    counter: 0,
    prefix: "unique"
};

ko.bindingHandlers.uniqueFor = {
    init: function(element, valueAccessor) {
        var after = ko.bindingHandlers.uniqueId.counter + (ko.utils.unwrapObservable(valueAccessor()) === "after" ? 0 : 1);
        element.setAttribute("for", ko.bindingHandlers.uniqueId.prefix + after);
    }
};

ko.bindingHandlers.tooltip = {
    init: function(element, valueAccessor) {
        var identifier = $(element).attr("data-toggle");

        if (!identifier)
            identifier = "tooltip-primary";

        $(element).tooltip()
            .data("bs.tooltip")
            .tip()
            .addClass(identifier);
    }
};

ko.bindingHandlers.datepicker = {
    init: function(element, valueAccessor, allBindingsAccessor) {
        var options = allBindingsAccessor().datepickerOptions || {};

        $(element).datepicker(options);
        $(element).datepicker("setDate", new Date());
        $(element).datepicker("update");

        ko.utils.registerEventHandler(element, "changeDate", function(event) {
            var value = valueAccessor();
            if (ko.isObservable(value)) {
                value(event.date);
            }
        });
    },
    update: function(element, valueAccessor) {
        var widget = $(element).data("datepicker");
        if (widget) {
            widget.date = ko.utils.unwrapObservable(valueAccessor());
            widget.setValue();
        }
    }
};

ko.bindingHandlers.timepicker = {
    init: function(element, valueAccessor, allBindingsAccessor) {
        var options = allBindingsAccessor().timepickerOptions || {};
        var handler = allBindingsAccessor().changeHandler || null;
        var minTime = allBindingsAccessor().minTime || null;
        $(element).timepicker(options);

        minTime.subscribe(function(val) {
            $(element).timepicker("option", "minTime", val);
        });

        ko.utils.registerEventHandler(element, "change", function(event) {
            if (handler != null) {
                handler(element);
            }
            var value = valueAccessor();
            if (ko.isObservable(value)) {
                value($(element).val());
            }
        });
    },
    update: function(element, valueAccessor) {
        var widget = $(element).data("timepicker");
        if (widget) {

            widget.date = ko.utils.unwrapObservable(valueAccessor());
            widget.setValue();
            viewModel.changeDate(widget.date);
        }
    }
};

ko.bindingHandlers.circleProgress = {
    drawInactive: function(iProgressCTX) {
        iProgressCTX.lineCap = 'square';

        var centerX = iProgressCTX.canvas.width / 2;
        var centerY = iProgressCTX.canvas.height / 2;
        // it is supposed that it's square
        var size = iProgressCTX.canvas.width;
        var halfSize = size / 2;

        //outer ring
        iProgressCTX.beginPath();
        iProgressCTX.lineWidth = 2;
        iProgressCTX.strokeStyle = "#b9c4ca";
        iProgressCTX.arc(centerX, centerY, 0.97 * halfSize, 0, 2 * Math.PI);
        iProgressCTX.stroke();

        //progress bar
        iProgressCTX.beginPath();
        iProgressCTX.lineWidth = 0.06 * size;
        iProgressCTX.fillStyle = "#b9c4ca";
        iProgressCTX.arc(centerX, centerY, 0.85 * halfSize, 0, 2 * Math.PI);
        iProgressCTX.stroke();

    },
    drawProgress: function(bar, percentage, pCaption, color) {
        var barCTX = bar.getContext("2d");
        var quarterTurn = Math.PI / 2;
        var endingAngle = ((2 * percentage) * Math.PI) - quarterTurn;
        var startingAngle = 0 - quarterTurn;

        var centerX = barCTX.canvas.width / 2;
        var centerY = barCTX.canvas.height / 2;
        // it is supposed that it's square
        var size = barCTX.canvas.width;
        var halfSize = size / 2;

        barCTX.clearRect(0, 0, size, size);
        barCTX.lineCap = "butt"; //"butt" || "round" || "square";
        //
        if (color == null)
            color = "#2f596b";

        barCTX.beginPath();
        barCTX.lineWidth = 0.06 * size;
        barCTX.strokeStyle = color;
        barCTX.arc(centerX, centerY, 0.85 * halfSize, startingAngle, endingAngle);
        barCTX.stroke();

        if (isNaN(percentage))
            pCaption.textContent = "n/a";
        else
            pCaption.textContent = (parseInt(percentage * 100, 10)) + "%";
    },
    init: function(element, valueAccessor, allBindingsAccessor) {
        var binding = ko.bindingHandlers.circleProgress;

        var bindingValues = valueAccessor();
        var bindingOptions = bindingValues.options;
        var fontSize = bindingOptions.size / 5;

        // create two canvases 
        var inactiveCanvas = document.createElement("canvas");
        inactiveCanvas.setAttribute("width", bindingOptions.size);
        inactiveCanvas.setAttribute("height", bindingOptions.size);
        inactiveCanvas.setAttribute("class", "progress-inactive");
        var activeCanvas = document.createElement("canvas");
        activeCanvas.setAttribute("width", bindingOptions.size);
        activeCanvas.setAttribute("height", bindingOptions.size);
        activeCanvas.setAttribute("class", "progress-active");
        activeCanvas.style.position = "absolute";
        activeCanvas.style.top = "0px";
        activeCanvas.style.left = "0px";

        var p = document.createElement("p");
        p.style.position = "absolute";
        p.style.width = bindingOptions.size + "px";
        p.style.height = bindingOptions.size + "px";
        p.style.lineHeight = bindingOptions.size + "px";
        p.style.top = "0px";
        p.style.left = "0px";

        p.style.fontSize = fontSize + "px";
        p.style.fontWeight = "bold";
        p.style.textAlign = "center";

        element.style.position = "relative";
        element.style.display = "inline-block";

        // add them to div
        element.appendChild(inactiveCanvas);
        element.appendChild(activeCanvas);
        element.appendChild(p);

        binding.drawInactive(inactiveCanvas.getContext('2d'));
        binding.drawProgress(activeCanvas, bindingValues.value, p, bindingOptions.color);
    },
    update: function(element, valueAccessor, allBindingsAccessor) {

        var bindingValues = valueAccessor();
        var activeCanvas = element.children[1];
        var p = element.children[2];

        var binding = ko.bindingHandlers.circleProgress;
        var bindingOptions = bindingValues.options;

        binding.drawProgress(activeCanvas, bindingValues.value, p, bindingOptions.color);
    }
}

ko.virtualElements.allowedBindings.stopBinding = true;