

let developmentConfig = {
    isProduction: false,
    enableDatadog: false,
    stripePublishableKey: "pk_test_cz9mF5Fu1zcqXt2IYlDJsMwe",
    googleAnalyticsId: "UA-100354578-1"
};

let productionConfig = {
    isProduction: true,
    enableDatadog: true,
    stripePublishableKey: "pk_live_uylQ0DB8LQTIO7IKaSB057kF",
    googleAnalyticsId: "UA-52626549-1"
};

let config = PRODUCTION ? productionConfig : developmentConfig;

export default config;