ko.extenders.numericOnly = function(target, precision) {
    var self = this;

    target.isValid = ko.observable();

    //create a writable computed observable to intercept writes to our observable
    var result = ko.pureComputed({
        read: target,  //always return the original observables value
        write: function(newValue) {
            var current = target(),
                roundingMultiplier = Math.pow(10, precision),
                newValueAsNum = isNaN(newValue) || newValue == null || newValue == "" ? null : +newValue,
                valueToWrite = isNaN(newValueAsNum) || newValueAsNum == null || newValue == "" ? null :
                    Math.round(newValueAsNum * roundingMultiplier) / roundingMultiplier;

            var isInvalid = valueToWrite === null && newValue !== null && newValue !== "";

            target.isValid(!isInvalid);
            if (isInvalid)
                valueToWrite = current;

            //only write if it changed
            if (valueToWrite !== current) {
                target(valueToWrite);
            } else {
                //if the rounded value is the same, but a different value was written, force a notification for the current field
                if (newValue !== current) {
                    target.notifySubscribers(valueToWrite);
                }
            }
        }
    }).extend({ notify: "always" });

    result.isValid = target.isValid;

    //initialize with current value to make sure it is rounded appropriately
    result(target());

    //return the new computed observable
    return result;
};

function initValidator(validator) {
    //add some sub-observables to our observable
    validator.errorMessages = !ko.isObservable(validator.errorMessages) ? ko.observable({}) : validator.errorMessages;
    validator.hasError = !ko.isObservable(self.hasError) ? ko.computed(function () {
        return Object.keys(validator.errorMessages()).length > 0;
    }) : validator.hasError;

    validator.validationMessage = !ko.isObservable(self.validationMessage) ? ko.computed(function () {
        var result = [];

        for (var f in validator.errorMessages()) {
            if (validator.errorMessages().hasOwnProperty(f)) {
                result.push(validator.errorMessages()[f]);
            }
        }

        return result.join("\n");
    }) : validator.validationMessage;
}

ko.extenders.required = function (target, options) {
    //add some sub-observables to our observable
    initValidator(target);

    var validatorKey = "requiredValidator";

    target.activeRequiredValidation = ko.observable(options.active);
    target.activateRequiredValidation = function () {
        target.activeRequiredValidation(true);
        validate(target());

        return $.when();
    };

    target.deactivateRequiredValidation = function () {
        target.activeRequiredValidation(false);
        validate(target());
    };

    //define a function to do validation
    function validate(newValue) {

        if (target.activeRequiredValidation()) {
            if (newValue) {
                delete target.errorMessages()[validatorKey];
            } else {
                target.errorMessages()[validatorKey] = options.overrideMessage || "This field is required";
            }
        } else {
            delete target.errorMessages()[validatorKey];
        }

        target.errorMessages.valueHasMutated();
    }

    //initial validation
    validate(target());
    //validate whenever the value changes
    target.subscribe(validate);
    //return the original observable
    return target;
};

ko.extenders.maxLength = function (target, options) {
    initValidator(target);

    var validatorKey = "maxLengthValidator";

    target.activeMaxLengthValidation = ko.observable(options.active);
    target.activateMaxLengthValidation = function () {
        target.activeMaxLengthValidation(true);
        validate(target());
    };

    target.deactivateMaxLengthValidation = function () {
        target.activeMaxLengthValidation(false);
        validate(target());
    };

    //define a function to do validation
    function validate(newValue) {
        if (target.activeMaxLengthValidation()) {
            if (newValue == null)
                return;

            var hasError = newValue.length > options.maxLength;
            if (hasError) {
                target.errorMessages()[validatorKey] = options.overrideMessage || "Length of the field exceed maximum length";
            } else {
                delete target.errorMessages()[validatorKey];
            }
        } else {
            delete target.errorMessages()[validatorKey];
        }

        target.errorMessages.valueHasMutated();

    }

    //initial validation
    validate(target());
    //validate whenever the value changes
    target.subscribe(validate);
    //return the original observable
    return target;
};


ko.extenders.serverCheck = function (target, options) {
    initValidator(target);

    var validatorKey = "serverCheckValidator";

    target.activeServerCheckValidation = ko.observable(options.active);
    target.activateServerCheckValidation = function () {
        target.activeServerCheckValidation(true);
        return validate(target());
    };

    target.deactivateServerCheckValidation = function () {
        target.activeServerCheckValidation(false);
        //validate(target());
    };

    //define a function to do validation
    function validate(newValue) {

        var deferred = $.Deferred();
        if (target.activeServerCheckValidation() && !newValue) {
            target.errorMessages()[validatorKey] = "This field is required";
            target.errorMessages.valueHasMutated();
            deferred.resolve();
        } else if (target.activeServerCheckValidation()/* && !target.hasError()*/) {
            var data = {};
            data[options.paramName] = newValue;

            $.post(options.url, data).done(function (result) {
                if (typeof (result) === "boolean" && result) {
                    delete target.errorMessages()[validatorKey];
                } else {
                    target.errorMessages()[validatorKey] = result;
                }
                target.errorMessages.valueHasMutated();

            }).fail(function (result) {
                target.errorMessages()[validatorKey] = newValue ? "" : options.overrideMessage || "Value is not valid";
                target.errorMessages.valueHasMutated();
            }).always(function () {
                deferred.resolve();
            });
        }
        else {
            delete target.errorMessages()[validatorKey];
            target.errorMessages.valueHasMutated();
            deferred.resolve();
        }

        return deferred;
    }

    //initial validation
    validate(target());
    //validate whenever the value changes
    target.subscribe(validate);
    //return the original observable
    return target;
};
