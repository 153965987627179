import { datadogRum } from "@datadog/browser-rum";

import config from "./config.js"

//console.log(`enableDatadog is: ${config.enableDatadog}`, config.enableDatadog);

if (config.enableDatadog) {
    datadogRum.init({
        applicationId: "d98f5402-7b8d-443b-8745-8359be6e59fb",
        clientToken: "pubf8c2bda621019f63218d1de9b1bd10c4",
        site: "datadoghq.com",
        service: "ninja",
        env: "ninjaoutreach-prod",
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: "mask-user-input"
    });
    datadogRum.startSessionReplayRecording();
}

