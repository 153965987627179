import ko from "knockout";
import $ from "jquery";
/*import * as Ladda from 'ladda';*/

export default class SignupLightModel {
    constructor() {
        this.email = ko.observable()
            .extend({ required: { overrideMessage: "", active: false } });
        this.password = ko.observable()
            .extend({ required: { overrideMessage: "", active: false } });

        this.signupErrors = ko.observable();
        this.inProgress = ko.observable(false);

        this.googleUserId = null;

        let self = this;

        this.hasErrors = ko.computed(function() {
            return !!self.signupErrors();
        });
    }

    disableSubmit() {
        this.inProgress(true);
        //this.l.start();
    }

    signIn() {
        const self = this;

        self.disableSubmit();

        self.email.activateRequiredValidation();
        self.password.activateRequiredValidation();

        if (self.email.hasError() || self.password.hasError()) {
            return false;
        }
        self.email.deactivateRequiredValidation();
        self.password.deactivateRequiredValidation();

        return true;
    }
}

ko.applyBindings(new SignupLightModel(), document.getElementById("njo-app"));